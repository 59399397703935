import React from "react"
import Loadable from "@loadable/component"
// Style Imports
// import './styles.scss'
import { getLocalizedURL } from '@src/helper';

// SEO
import Seo from '@components/utility/SEO'
// Structural Imports
import Layout from "@components/structure/Layout.jsx"
// Content Imports
import Anchor from "@components/content/anchor.jsx"
import Hero from "@components/content/hero/Hero.jsx"
import Image from "@components/content/fractals-image.jsx"
import DoctorDiscussionGuideCTA from "@components/content/DoctorDiscussionGuide/DoctorDiscussionGuide.jsx"
const BloodVialIcon = Loadable(() => import('@components/content/icons/BloodVial.jsx'))
const CalendarSmallIcon = Loadable(() => import('@components/content/icons/CalendarSmall.jsx'))
const PillGlowIcon = Loadable(() => import('@components/content/icons/PillGlow.jsx'))
const StethoscopeSmallIcon = Loadable(() => import('@components/content/icons/StethoscopeSmall.jsx'))
const SunMoonIcon = Loadable(() => import('@components/content/icons/SunMoon.jsx'))
const UrineTestIcon = Loadable(() => import('@components/content/icons/UrineTest.jsx'))

export const Head = () => (
	<>
		<script type='application/ld+json'
			dangerouslySetInnerHTML={{
				__html: JSON.stringify({
					"@context": "http://schema.org",
					"type": "VideoObject",
					"name": "Cómo LUPKYNIS® (voclosporina) está ayudando a las personas",
					"description": "Vea cómo las personas están luchando contra la nefritis lúpica. Mire sus historias para ver cómo LUPKYNIS los ayuda a hacerlo.",
					"thumbnailUrl": "https://www.lupkynis.com/images/taking-lupkynis/watch-real-people-stories-poster-pt-2.png",
					"uploadDate": "2022-10-01",
					"embedUrl": "https://www.lupkynis.com" + getLocalizedURL('taking-lupkynis','es'),
					"duration": "PT5M27S",
				})
			}}
		/>
	</>
)

const takingLupkynis = () => (
    <Layout addedClass="page--taking-lupkynis">
        <Seo 
            title="Tomar LUPKYNIS&reg; (voclosporina) | Cómo tomar LUPKYNIS&reg; (voclosporina)" 
            description="Hable con su médico sobre qué esperar cuando tome LUPKYNIS. Obtenga más información sobre cómo tomar LUPKYNIS, incluida la información y el cronograma de dosificación. Consulte la Información de prescripción y seguridad completa y el RECUADRO DE ADVERTENCIA." 
            canonicalURL={"https://www.lupkynis.com" + getLocalizedURL('taking-lupkynis','es')} />
        <Hero addedClass="bg-coral hero--string">
            <div className="col-wrapper">
                <div className="container">
                    <div className="hero__content">
                        <h1 className="heading heading--accent">TOMAR LUPKYNIS</h1>
                        <h2>Qué <span>esperar</span> al tomar LUPKYNIS</h2>
                        <p><strong>Cuando tome LUPKYNIS según las indicaciones de su médico, puede ayudar a mantener su función renal.</strong></p>
                        <p>Mientras toma LUPKYNIS, las visitas de rutina con su médico lo ayudan a mantenerse informado sobre el progreso de su tratamiento y la función renal, y también pueden ayudar a realizar un seguimiento de sus síntomas.</p>
                    </div>
                    <div className="hero__image">
                        <Image data={{
                            desktopSrc: 'taking-lupkynis/hero.png', 
                            mobileSrc: 'taking-lupkynis/hero--mobile.png', 
                            altText: 'Imagen del envase de LUPKYNIS® (voclosporina)'
                        }} />
                    </div>
                </div>
            </div>
            <div className="icon-bar col-wrapper bg-light-green text-center">
                <div className="container">
                    <div className="icon-bar__item">
                        <div className="icon">
                            <UrineTestIcon />
                        </div>
                        <div className="icon-bar__content">
                            <p><strong>Los análisis de orina (uroanálisis)</strong> monitorean los niveles de proteína (proteinuria) y sangre (hematuria) en la orina. La proteinuria es uno de los signos clave de la nefritis lúpica activa.</p>
                        </div>
                    </div>
                    <div className="icon-bar__item">
                        <div className="icon">
                            <BloodVialIcon />
                        </div>
                        <div className="icon-bar__content">
                            <p><strong>Los análisis de sangre</strong> se utilizan para verificar cómo están funcionando los riñones</p>
                        </div>
                    </div>
                    <div className="icon-bar__item">
                        <div className="icon">
                            <StethoscopeSmallIcon />
                        </div>
                        <div className="icon-bar__content">
                            <p><strong>Las visitas de rutina</strong> garantizan que usted siga encaminado con su tratamiento</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copy-block text-center">
                <p>Con Aurinia Alliance<sup>&reg;</sup>, su programa de apoyo personalizado, se conectará con un administrador de casos exclusivo del personal de enfermería que estará aquí con usted en cada paso del camino en su recorrido de tratamiento con LUPKYNIS. Es fácil inscribirse. Haga <Anchor url="https://www.auriniaalliance.com" target="_blank">clic aquí</Anchor> para obtener más información sobre el apoyo individual que recibirá y comience hoy mismo.</p>
            </div>
        </Hero> 
        <section id="HowToTakeLupkynis" className="bg-light-pink text-center">
            <div className="col-wrapper">
                <div className="container how-to-top-section">
                    <div className="how-to-img">
                        <Image data={{
                            desktopSrc: 'taking-lupkynis/howto.png', 
                            mobileSrc: 'taking-lupkynis/howto--mobile.png'
                        }} />
                    </div>
                    <div className="copy-block">
                        <h2 className="heading heading--accent">CÓMO TOMAR LUPKYNIS</h2>
                        <ul className="circle-bullet-list text-left">
                            <li>LUPKYNIS es un medicamento oral para el tratamiento de adultos con nefritis lúpica activa.</li>
                            <li>LUPKYNIS debe tragarse entero con el estómago vacío, con una diferencia de 12&nbsp;horas lo más cercana posible (con al menos 8&nbsp;horas entre las dosis) </li>
                            <li>Si se omite una dosis, tómela lo antes posible en las 4&nbsp;horas posteriores a la dosis omitida.</li>
                            <li>Si pasaron más de 4&nbsp;horas, espere hasta el horario habitual programado para tomar su próxima dosis regular — no duplique su próxima dosis.</li>
                        </ul>
                    </div>
                </div>
                <div className="copy-block">
                    <p>Antes de comenzar con LUPKYNIS, hable con su médico sobre todos los medicamentos que está tomando actualmente. Su médico determinará la dosis correcta para usted cuando le recete LUPKYNIS. Lo monitorearán durante todo el transcurso de su tratamiento y también pueden cambiar su dosis. Siempre tome LUPKYNIS según las indicaciones de su médico.</p>
                </div>
            </div>
            <div className="container">
                <div className="copy-block">
                    <h3 className="heading heading--subhead">Una pauta posológica que se adapte a su vida y no al revés</h3>
                </div>
                <div className="icon-bar col-wrapper bg-pink">
                    <div className="container">
                        <div className="icon-bar__item">
                            <div className="icon">
                                <PillGlowIcon />
                            </div>
                            <div className="icon-bar__content">
                                <p><strong>Se toma por vía oral,</strong> no mediante inyección</p>
                            </div>
                        </div>
                        <div className="icon-bar__item">
                            <div className="icon">
                                <SunMoonIcon />
                            </div>
                            <div className="icon-bar__content">
                                <p><strong>Una dosis estándar</strong> es de 3&nbsp;cápsulas, 2&nbsp;veces al día</p>
                            </div>
                        </div>
                        <div className="icon-bar__item">
                            <div className="icon">
                                <CalendarSmallIcon />
                            </div>
                            <div className="icon-bar__content">
                                <p><strong>La pauta posológica</strong> es la misma durante todo el tratamiento</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="polaroid-sticky">
                <div className="container">
                    <Image data={{
                        desktopSrc: 'taking-lupkynis/polaroid-es.png', 
                        mobileSrc: 'taking-lupkynis/polaroid--mobile-es.png', 
                        altText:'Suriya, quien comenzó el tratamiento en 2021'
                    }} />
                    <div className="green-post ripped-tape">
                        <div className="post-inner">
                            <p className="double-quotes">Comencé a tomar <strong>3 cápsulas dos veces al día</strong> y a menudo establecí alarmas en mi teléfono para recordarme que debía tomarlas, lo que me mantenía en el buen camino. Por lo general, tomo las cápsulas por la mañana y después de la cena con un vaso de agua y <strong>me encantan los resultados que estoy recibiendo.</strong></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id="Treatment" className="col-wrapper bg-purple">
            <div className="container">
                <div className="copy-block">
                    <h2 className="heading heading--accent">SEGURIDAD DE LUPKYNIS</h2>
                    <h3 className="heading heading--subhead">Siéntase informado sobre el tratamiento</h3>
                    <p>LUPKYNIS se analizó en estudios clínicos en los que participaron más de 350&nbsp;personas de diferentes razas y orígenes étnicos.</p>
                    <p>LUPKYNIS puede causar efectos secundarios graves, por lo que es importante que hable con su médico de inmediato si experimenta alguno de estos <Anchor url="#ISI">signos o síntomas</Anchor>.</p>
                    <p><strong>Los efectos secundarios más frecuentes de LUPKYNIS son diarrea, dolor de cabeza, tos, infección de las vías urinarias, dolor de estómago, acidez estomacal y pérdida del cabello (alopecia). Estos no son todos los posibles efectos secundarios de LUPKYNIS. Para obtener más información, hable con su médico.</strong></p>
                </div>
                <div>
                    <Image data={{
                        desktopSrc: 'taking-lupkynis/group.png', 
                        mobileSrc: 'taking-lupkynis/group--mobile.png'
                    }} />
                </div>
            </div>
        </section>
        <DoctorDiscussionGuideCTA />    
    </Layout>
)

export default takingLupkynis
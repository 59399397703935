import React, { useContext } from "react"
import Loadable from "@loadable/component"
import AppContext from '@src/context'

// Style Imports
import "./DoctorDiscussionGuide.scss"
// Content Imports
import Anchor from "@components/content/anchor.jsx"
import { getLocalizedURL } from "../../../helper"
const ConversationIcon = Loadable(() => import('@components/content/icons/Conversation.jsx'))

const DoctorDiscussionGuideCTA = () => {
    const ctx = useContext(AppContext);
    const lang = ctx.currentLang;
    const content = {
        h2: {
            en: 'Feel confident talking to your doctor about LUPKYNIS',
            es: 'Siéntase seguro al hablar con su médico sobre LUPKYNIS'
        },
        p: {
            en: 'Our downloadable doctor discussion guide can help make starting the conversation easier.',
            es: 'Nuestra guía de análisis para médicos que se puede descargar puede facilitar el inicio de la conversación.'
        },
        label: {
            en: 'Download',
            es: 'Descargar'
        }
    };

	return (
		<section id="DoctorDiscussionGuide" className="bg-light-green text-center">
            <div className="container">
                <div className="icon">
                    <ConversationIcon />
                </div>
                <h2>{content.h2[lang]}</h2>
                <p>{content.p[lang]}</p>
                <div>
                    <Anchor url={getLocalizedURL('doctor-discussion-guide.pdf', lang)} target="_blank" linkClass="button">{content.label[lang]}</Anchor>
                </div>
            </div>
        </section>
    );
};

export default DoctorDiscussionGuideCTA;